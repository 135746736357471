<template>
    <button>
        <slot></slot>
        <svg class="arrow">
            <use xlink:href="#icon-arrow-right" href="#icon-arrow-right"></use>
        </svg>

        <svg class="chevron">
            <use xlink:href="#icon-chevron" href="#icon-chevron"></use>
        </svg>
    </button>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {

    };
</script>
