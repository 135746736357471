/* eslint-disable */

// http://pixijs.io/pixi-particles-editor/#
var LIBRARY = [
    '/static/img/confetti/special-1.png',
    '/static/img/confetti/special-2.png',

    '/static/img/confetti/circle-purple.png',
    '/static/img/confetti/circle-blue.png',
    '/static/img/confetti/circle-green.png',

    '/static/img/confetti/rect-pink.png',
    '/static/img/confetti/rect-purple.png',
    '/static/img/confetti/rect-green.png',
    '/static/img/confetti/rect-yellow.png',
    '/static/img/confetti/rect-blue.png',

    '/static/img/confetti/star-yellow.png',
    '/static/img/confetti/star-green.png',
    '/static/img/confetti/star-pink.png',

    '/static/img/confetti/special-3.png',
    '/static/img/confetti/special-4.png'
];

var PI2 = Math.PI * 2;

var sineOutEasing = function (t, b, c, d) {
    return c * Math.sin(t/d * (Math.PI/2)) + b;
};

var sineInEasing = function (t, b, c, d) {
    return -c * Math.cos(t/d * (Math.PI/2)) + c + b;
};

var expoOutEasing = function (t, b, c, d) {
    return (t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b;
    // return (t==d) ? b+c : 0 * (-0.03125 + 1) + 1;
};

var expoInEasing = function (t, b, c, d) {
    return (t==0) ? b : c * Math.pow(2, 10 * (t/d - 1)) + b;
};

var cubicInEasing = function (t, b, c, d) {
    return c*(t/=d)*t*t + b;
}

function Confetti()
{
    this._initialize();
}

var p = Confetti.prototype;

p._initialize = function()
{
    this.sprite = new PIXI.Sprite();
    this.containers = [];
    for( var i = 0; i < LIBRARY.length; i++ )
    {
        var container = new PIXI.ParticleContainer( 1000, {
            tint: true,
            scale: true,
            rotation: true
        } );
        this.sprite.addChild( container );
        this.containers.push( container );
    }
    this.lastTime = performance.now();
    this.clock = TweenMax.to( {foo:0}, 0.1, {foo:1, repeat:-1, onUpdate:this.renderParticles.bind(this) } );

    this.particleList = [];
};

p.renderParticles = function()
{
    if( this.particleList.length === 0 )
    {
        this.clock.pause();
    }

    var time = performance.now();
    var deltaTime = time - this.lastTime;
    this.lastTime = time;

    var len = this.particleList.length;
    var origLen = len;
    var deleteList = [];
    var state;

    while( len-- > 0 )
    {
        state = this.particleList[len]._confetti.update( deltaTime );
        if( state === -1 )
        {
            deleteList.push( this.particleList[len] );
        }
    }

    len = deleteList.length;

    while( len-- > 0 )
    {
        this.particleList[len]._confetti.container.removeChild( this.particleList[len] );
        this.particleList.splice( this.particleList.indexOf( this.particleList[len] ), 1 );
    }
};

p.spawn = function( pAmount, pWidth, pHeight, pCenter, pSettings )
{
    var p;
    var rnd;
    var len = LIBRARY.length;

    var settings = {
        minStartScale: 0.1,
        varStartScale: 0.3,
        minEndScale: 0.1,
        varEndScale: 0.3,
        startGravity: 0,
        endGravity: pHeight/2,
        minDeath:2000,
        varDeath:1000,
    }

    if( pSettings )
    {
        for( var i in pSettings )
        {
            settings[ i ] = pSettings[ i ];
        }
    }

    this.sprite.x = pCenter.x;
    this.sprite.y = pCenter.y;
    var self = this;
    var container;
    var targetPosMinX = pWidth / -2;
    var targetPosMinY = pHeight / -2;

    for( var i = 0; i < pAmount; i++ )
    {
        rnd = Math.floor( ( Math.random() * len + Math.random() * len ) / 2 );
        container = this.containers[ rnd ];
        p = PIXI.Sprite.fromImage( LIBRARY[rnd] );
        p.anchor.set( 0.5, 0.5 );
        p.x = 0;
        p.y = 0;
        p._confetti = ( function( pSelf, pContainer, pIndex ){

            var processor = {
                container: pContainer,
                age: 0,
                startAlpha: 1,
                endAlpha: 0.001,
                targetPos: new PIXI.Point( ((targetPosMinX + pWidth * Math.random()) + (targetPosMinX + pWidth * Math.random())) / 2, ((targetPosMinY + pHeight * Math.random() ) + (targetPosMinY + pHeight * Math.random())) / 2 ),
                startScale: settings.minStartScale + Math.random() * settings.varStartScale,
                endScale: settings.minEndScale + Math.random() * settings.varEndScale,
                startRotation: Math.random() * PI2,
                endRotation: Math.random() * PI2 * Math.random() * 2,
                death: settings.minDeath + Math.random() * settings.varDeath,
                gravityStart: settings.startGravity,
                gravityEnd: settings.endGravity,
                update:function( pDeltaTime )
                {
                    pSelf._confetti.age += pDeltaTime;

                    if( pSelf._confetti.age > pSelf._confetti.death )
                    {
                        return -1;
                    }

                    var lifeTime = Math.min( 1, pSelf._confetti.age / pSelf._confetti.death );

                    // pSelf.alpha = Math.max( 0, pSelf._confetti.startAlpha + lifeTime * pSelf._confetti.endAlpha );
                    pSelf.alpha = 1 - cubicInEasing( lifeTime, pSelf._confetti.endAlpha, pSelf._confetti.startAlpha, 1 );

                    pSelf.x = expoOutEasing( lifeTime, 0, pSelf._confetti.targetPos.x, 0.75 );
                    pSelf.y = expoOutEasing( lifeTime, 0, pSelf._confetti.targetPos.y, 0.75 );
                    pSelf.rotation = expoOutEasing( lifeTime, pSelf._confetti.startRotation, pSelf._confetti.endRotation, 1 );
                    pSelf.scale.set( sineOutEasing( lifeTime, pSelf._confetti.startScale, pSelf._confetti.endScale, 0.75 ) );
                    pSelf._confetti.velocity *= pSelf._confetti.accelerate;

                    pSelf.y += expoInEasing( lifeTime, pSelf._confetti.gravityStart, pSelf._confetti.gravityEnd, 1 );
                }
            };

            pSelf.scale.set( processor.startScale );
            pSelf.rotation = processor.startRotation;

            return processor;
        })( p, container, i );

        container.addChild( p );
        self.particleList.push( p );
    }

    this.lastTime = performance.now();
    this.clock.play();
}

export default Confetti;
