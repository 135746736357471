<template>
    <div class="explain-view">
        <div class="panel">
            <h2>Hoe werkt Lotto Stop45?</h2>

            <v-slider ref="slider" autoplay pauseOnHover>
                <div>
                    <div class="slider-content" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter">

                        <img class="visual" src="/static/img/explain/1.jpg?v3" />
                        <p>Je ziet 6 Lotto ballen die 1 voor 1 draaien. Druk op de knop om de bal te stoppen op een zo hoog mogelijk getal. Het getal waarop de bal stopt vermenigvuldigen we met 100 en dat is de hoogte van het bedrag waarop je kans maakt! Je maakt kans op maximaal &euro;&nbsp;4.500,-!</p>
                    </div>
                </div>
                <div>
                    <div class="slider-content" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter">
                        <img class="visual" src="/static/img/explain/2.jpg?v3" />
                        <p>Stop je de eerste bal bijvoorbeeld op getal 41, dan maak je kans op &euro;&nbsp;4.100,-! Vind je dit een mooi bedrag? Dan mag je stoppen! Denk je dan je het beter kan? Speel dan door!</p>
                    </div>
                </div>
                <div>
                    <div class="slider-content" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter">
                        <img class="visual" src="/static/img/explain/3.jpg?v3" />
                        <p>LET OP! Je speelt alleen mee met de laatste bal die je gestopt hebt! Is dit getal lager dan op de voorgaande bal, dan is het geldbedrag waarvoor je meespeelt ook lager. Je mag zo vaak meespelen als je wilt. De hoogste score wordt automatisch opgeslagen.</p>
                    </div>
                </div>
            </v-slider>
        </div>

        <v-button @click.native="toGame">Start</v-button>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { TimelineMax } from '@/vendor';
    import LottoButton from '@/app/components/LottoButton';
    import Slider from '@/app/components/Slider';

    export default {
        components: {
            'v-button': LottoButton,
            'v-slider': Slider
        },

        data()
        {
            return {
                slickOptions: {
                    arrows: false,
                    fade: true,
                    useCSS: false
                },

                slides: [
                    {
                        copy: 'Raad 6 keer of de volgende Lotto bal hoger of lager is en maak kans op €4.500!'
                    },
                    {
                        copy: 'Je ziet een bal in beeld, wat denk je? Is de volgende bal hoger of lager? Klik op de bijbehorende pijl. (Tip: Lotto is een spel met 45 ballen)'
                    },
                    {
                        copy: 'Bij 1 goed antwoord maak je al kans op €500. Dit bedrag loopt steeds op, tot zelfs €4.500 bij 6 goede antwoorden!'
                    },
                    {
                        copy: 'Vergeet niet om na afloop je gegevens in te vullen. Je maakt alleen kans als je je gegevens achterlaat.'
                    },
                    {
                        copy: 'De volledige speluitleg en de actievoorwaarden vind je <a href="/static/pdf/lotto_actievoorwaarden.pdf" target="_blank">hier</a>.'
                    }
                ],

                currentSlide: 0,
                destroyCalled: false
            };
        },

        methods: {
            handleAfterChange( pEvent, pSlick, pPreviousSlide, pCurrentSlide )
            {
                this.currentSlide = pCurrentSlide;
            },

            prev()
            {
                this.$refs.slick.prev();
            },

            next()
            {
                this.$refs.slick.next();
            },

            onMouseLeave( e )
            {
                if( this.$refs.slider )
                {
                    this.$refs.slider.onMouseLeave( e );
                }
            },

            onMouseEnter( e )
            {
                if( this.$refs.slider )
                {
                    this.$refs.slider.onMouseEnter( e );
                }
            },

            toGame()
            {
                this.destroyCalled = true;
                // this.$refs.slick.destroy();

                // if( screenfull.enabled && this.$helpers.browserCheck().isMobile )
                // {
                //     screenfull.request();
                // }

                this.$router.push({ name: 'Game' });
            },

            getAnimateIn()
            {
                let tl = new TimelineMax();

                tl.from( '.panel', 0.3, { y: 50, opacity: 0 });
                tl.from( '.button', 0.3, { opacity: 0 }, 0.3 );

                return tl;
            }
        }
    };
</script>
