import * as yup from 'yup';

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht` // `${obj.path} is default`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `Dit is geen geldig ${obj.label}.`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                zipcode: {
                    label: 'Postcode',
                    name: 'zipcode',
                    placeholder: 'bijv. 1234AB',
                    tabindex: 9
                },

                house_num: {
                    label: 'Huisnummer',
                    name: 'house_num',
                    type: 'number',
                    min: 0,
                    tabindex: 10
                },

                house_num_add: {
                    name: 'house_num_add',
                    label: 'Toevoeging',
                    tabindex: 11
                }
            },

            validationSchema: yup.object().shape({
                zipcode: yup.string()
                    .label( 'postcode' )
                    .required()
                    .matches( /^[1-9][0-9]{3} {0,1}[A-Za-z]{2}$/ ),
                house_num: yup.number()
                    .label( 'huisnummer' )
                    .nullable()
                    .required()
                    .min( 1 ),
                house_num_add: yup.string()
                    .label( 'toevoeging' )
                    /* eslint-disable-next-line */
                    .matches( /^([A-z0-9\-])+$/, { excludeEmptyString: true })
                    .max( 10 )
            })
        };
    }
};
