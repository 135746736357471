<template>
    <div class="thanks-view">
        <div class="content">
            <h2><strong>Bedankt!</strong></h2>
            <p>We hebben je gegevens opgeslagen. Je maakt nu kans op <strong>&euro;&nbsp;{{ dottify( prize ) }},-</strong> De trekking vindt plaats in week 23. Lotto neemt contact op met de winnaar per mail.</p>

            <h4>Elke week kans op de jackpot én een welkomstcadeau</h4>

            <p>Met Lotto maak je elke zaterdag voor €2 kans op de jackpot van minimaal <a href="https://lotto.nederlandseloterij.nl/automatisch-meespelen/cadeau-kiezen?utm_source=lot-actiepagina-stop45&utm_campaign=stop45&utm_medium=ibr" target="_blank">€2.500.000</a>. En de Lotto Jackpot is óók nog eens de vaakst vallende Jackpot in Nederland. Meespelen met Lotto is spannend, leuk en heel eenvoudig.</p>

            <a :href="clickOut" target="_blank">
                <div class="cadeau-wrapper">
                    <div class="cadeau red">
                        <div class="spacer"></div>
                        <img class="back" src="/static/img/cadeau-rood-achter.png" />
                        <img class="prize" src="/static/img/lotto-loten.png" />
                        <img class="front" src="/static/img/cadeau-rood-voor.png" />
                        <img class="lid" src="/static/img/cadeau-rood-deksel.png" />
                    </div>
                    <div class="cadeau blue">
                        <div class="spacer"></div>
                        <img class="back" src="/static/img/cadeau-blauw-achter.png" />
                        <img class="prize" src="/static/img/bol-cadeaukaart.png" />
                        <img class="front" src="/static/img/cadeau-blauw-voor.png" />
                        <img class="lid" src="/static/img/cadeau-blauw-deksel.png" />
                    </div>
                    <div class="cadeau green">
                        <div class="spacer"></div>
                        <img class="back" src="/static/img/cadeau-groen-achter.png" />
                        <img class="prize" src="/static/img/mediamarkt-bon.png" />
                        <img class="front" src="/static/img/cadeau-groen-voor.png" />
                        <img class="lid" src="/static/img/cadeau-groen-deksel.png" />
                    </div>
                </div>
            </a>

            <h4>Wil je kans maken op nog meer prijzen? Speel dan nu Lotto Kies je Cadeau! Klik op de knop hieronder om mee te doen.</h4>
            <div class="button-wrapper">
                <a :href="clickOut" target="_blank"><v-button @click.native="toGame">Kies je Cadeau spelen</v-button></a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .loten{
        margin:1rem auto;
    }

    .button-wrapper{
        margin-bottom:2rem;
    }
</style>

<script>
    import LottoButton from '@/app/components/LottoButton';
    import { dottify } from '@/app/mixins';
    import { TimelineMax } from '@/vendor';

    export default {
        components: {
            'v-button': LottoButton
        },

        mixins: [ dottify ],

        computed: {
            prize()
            {
                return this.$store.getters[ 'game/prize' ];
            },

            entry()
            {
                return this.$store.getters[ 'entry/get' ]();
            },

            clickOut()
            {
                return 'https://lotto-cadeau.nederlandseloterij.nl/' + ( window._rootData.s === 'lot-perfect-data' ? '?utm_source=lot-perfect-data&utm_medium=ref&utm_campaign=lot-stop45kiescadeau' : '' );
            }
        },

        mounted()
        {
            if( window.Request_session_id )
            {
                if( !this.entry.telephone )
                {
                    // EM lead
                    this.$http.get( 'https://secureomg.nl/p.ashx?o=19712&e=623&f=pb&r=' + window.Request_session_id + '&t=' + this.$store.getters['entry/get']().id );
                }
                else
                {
                    // TM lead
                    this.$http.get( 'https://secureomg.nl/p.ashx?o=19712&e=507&f=pb&r=' + window.Request_session_id + '&t=' + this.$store.getters['entry/get']().id );
                }
            }

            let tl = new TimelineMax({ delay: 0.3, repeat: -1 });

            tl.set( '.cadeau.blue .prize, .cadeau.green .prize', { rotation: -54 });

            tl.staggerTo( '.cadeau .lid', 1, { y: '-100%', yoyo: true, repeat: 1, repeatDelay: 2.5, ease: Sine.easeInOut }, 4, 0 );
            tl.staggerFromTo( '.prize', 0.75, { y: '20%', scale: 0.3 }, { y: '-60%', scale: 1, yoyo: true, repeat: 1, repeatDelay: 2.5 }, 4, 0.4 );

            return tl;
        },

        methods: {
        }
    };
</script>
