<template>
    <div>
        <h2 class="encouragement" v-show="attempt >= 0">
            <template>{{ encouragement[0] }}</template><br/>
            Je maakt nu kans&nbsp;op<br/><span class="prize">€ {{ prize }},-</span><br/>
            <template><strong>{{ encouragement[1] }}</strong></template>
        </h2>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { TweenMax, TimelineMax } from '@/vendor';
    import { dottify } from '@/app/mixins';

    export default {
        mixins: [ dottify ],

        props: {
            attempt: {
                type: Number,
                default: 0
            },
            ballNum: {
                type: Number,
                default: null
            }
        },

        data()
        {
            return {
                positiveKreten: [
                    'Goed gedaan! Die is hoger!',
                    'Yeah! Goed bezig hoor!',
                    'Yes! Wat een goede poging!'
                ],
                negatieveKreten: [
                    'Oeps! Dat is lager dan de vorige bal.',
                    'Aiiii! Die bal is lager dan de vorige.',
                    'Balen! Helaas niet hoger dan de vorige bal.'
                ],
                neutraleKreten: [
                    'Oei, echt net niet hoger!',
                    'Ahh jammer! Hetzelfde!'
                ],

                incentives: [
                    'Probeer je het nog een keer?',
                    'Stop je, of speel je verder?',
                    'Waag je nog een gokje?'
                ]
            };
        },

        /*
            cases: 1e poging, 4 verschillende
        */

        computed: {
            prize()
            {
                return this.dottify( this.$store.getters[ 'game/prize' ] );
            },

            firstAttempt()
            {
                let texts = [
                    'Goede poging, maar dat kan vast wel beter!',
                    'Niet slecht voor een eerste poging!',
                    'Wauw! Wat goed! Is het beginnersgeluk?',
                    'Jaaaaaa! Meteen gestopt op 45!'
                ];

                let rtnLine;

                switch( true )
                {
                        case ( this.ballNum < 11 ):
                            rtnLine = texts[ 0 ];
                            break;
                        case ( this.ballNum < 26 ):
                            rtnLine = texts[ 1 ];
                            break;
                        case ( this.ballNum < 45 ):
                            rtnLine = texts[ 2 ];
                            break;
                        case ( this.ballNum === 45 ):
                            rtnLine = texts[ 3 ];
                            break;
                }

                return rtnLine;
            },

            lastAttempt()
            {
                let texts = [
                    'Leuk dat je hebt meegespeeld, dit is het bedrag waar je kans op maakt.',
                    'Dat is een mooi bedrag op je laatste poging!',
                    'Yeah! Wat een mooie laatste poging!',
                    'Wauw! Op de valreep gestopt op 45!'
                ];

                let rtnLine;

                switch( true )
                {
                        case ( this.ballNum < 11 ):
                            rtnLine = texts[ 0 ];
                            break;
                        case ( this.ballNum < 26 ):
                            rtnLine = texts[ 1 ];
                            break;
                        case ( this.ballNum < 45 ):
                            rtnLine = texts[ 2 ];
                            break;
                        case ( this.ballNum === 45 ):
                            rtnLine = texts[ 3 ];
                            break;
                }

                return rtnLine;
            },

            encouragement()
            {
                let randInc = Math.floor( Math.random() * this.incentives.length );
                let rtnArray;

                if( this.attempt < 0 )
                {
                    rtnArray = [ '', '' ];
                }
                if( this.attempt === 0 )
                {
                    rtnArray = [ this.firstAttempt, this.incentives[ randInc ] ];
                }
                else if( this.attempt === 5 )
                {
                    rtnArray = [ this.lastAttempt, 'Ga snel naar de volgende stap!' ];
                }
                else if( this.attempt > 0 )
                {
                    let randNeg = Math.floor( Math.random() * this.negatieveKreten.length );
                    let randPos = Math.floor( Math.random() * this.positiveKreten.length );
                    let randNeu = Math.floor( Math.random() * this.neutraleKreten.length );

                    if( this.ballNum < this.scores[ this.attempt - 1 ] )
                    {
                        rtnArray = [ this.negatieveKreten[ randNeg ], this.incentives[ randInc ] ];
                    }
                    else if( this.ballNum > this.scores[ this.attempt - 1 ] )
                    {
                        rtnArray = [ this.positiveKreten[ randPos ], this.incentives[ randInc ] ];
                    }
                    else
                    {
                        rtnArray = [ this.neutraleKreten[ randNeu ], this.incentives[ randInc ] ];
                    }
                }

                if( this.ballNum === 45 )
                {
                    rtnArray[1] = 'Dit is het maximale bedrag waar je kans op maakt.';
                }

                return rtnArray;
            }
        },

        watch: {
            attempt: function( pVal, pOldVal )
            {
                this.scores.push( this.ballNum );
                this.animIn();
            }
        },

        mounted()
        {
            this.scores = [];
            TweenMax.set( this.$el.querySelector( '.encouragement' ), { x: '-50%' });
        },

        methods: {
            hide()
            {
                let tl = new TimelineMax();

                tl.to( this.$el.querySelector( '.encouragement' ), 0.2, { opacity: 0 });

                return tl;
            },

            animIn()
            {
                let tl = new TimelineMax();
                tl.fromTo( this.$el.querySelector( '.encouragement' ), 0.3, { y: 30, opacity: 0 }, { y: 0, opacity: 1, ease: Back.easeOut });
            }
        }
    };
</script>
