<template>
    <div class="main-layout">
        <div class="lotto-logo"><img src="/static/img/logos/lotto.png" /></div>

        <div :class="{ 'content': true, [$route.name + '-view']: true }">
            <slot></slot>
        </div>

        <footer>
            <div class="content-wrapper">
                <img src="/static/img/logos/footer.png" />
                <img class="big" src="/static/img/logos/footer-big.png" />
                <p class="disclaimer">Voor het organiseren van Lotto is aan Lotto bv vergunning verleend door de Kansspelautoriteit, trekking Laan van Hoornwijck 55 te Rijswijk.<br />Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a @click="trackAv" href="/static/pdf/actievoorwaarden.pdf" target="_blank">actievoorwaarden</a>. | <a @click="trackPrivacyClickout" href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse Loterij</p>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { tracking } from '@/app/mixins';
    export default {
        mixins: [ tracking ],

        methods: {
            trackPrivacyClickout()
            {
                this.trackEvent( 'Klik op link - Privacy Statement', 'Link tracking', 'Nieuw venster - Privacy Statement - https://www.nederlandseloterij.nl/privacy' );
            },

            trackAv()
            {
                this.trackEvent( 'Klik op link - Actievoorwaarden', 'Link tracking', window.location.origin + this.avLink );
            }
        }
    };
</script>
