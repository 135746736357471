import browserCheck from './browserCheck';
import fingerprintjs2 from './fingerprintjs2';
import recaptcha from './recaptcha';
import tracking from './tracking';
import dottify from './dottify';
import socialShare from './socialShare';

import formConfig1 from './form/formConfig1';
import formConfig2 from './form/formConfig2';
import formConfig3 from './form/formConfig3';

export {
    browserCheck,
    fingerprintjs2,
    recaptcha,
    tracking,
    dottify,
    socialShare,

    formConfig1,
    formConfig2,
    formConfig3
};
