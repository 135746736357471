/* eslint-disable */

var PIXIManager = function( pSettings )
{
    this._settings = pSettings;
    this._initialize();
};

var p = PIXIManager.prototype;

p._initialize = function()
{
    PIXI.utils.skipHello();
    this.app = new PIXI.Application( this._settings.width, this._settings.height, { transparent:true } );
    this.stage = this.app.stage;
    this.canvas = this.app.view;
};

p.resize = function( pWidth, pHeight )
{
    this.app.renderer.resize( pWidth, pHeight );
};

module.exports = PIXIManager;
