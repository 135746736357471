<template>
    <div class="landing-view">
        <div class="ball-wrapper">
            <v-ball :dropped="false" showImmediate :number="41" :spinTime="0.1" ref="ball1"></v-ball>
            <v-ball :dropped="false" showImmediate :number="38" :spinTime="0.1" ref="ball2"></v-ball>
            <v-ball :dropped="false" :number="14" :spinTime="0.1" ref="ball3"></v-ball>
            <img class="motion" src="/static/img/ball-assets/motion.png" />
            <v-ball :dropped="false" :number="6" :spinTime="0.1" ref="ball4"></v-ball>
            <v-ball :dropped="false" :number="15" :spinTime="0.1" ref="ball5"></v-ball>
            <v-ball :dropped="false" :number="24" :spinTime="0.1" ref="ball6"></v-ball>
        </div>

        <v-stopknop @click.native="toGame" ref="stopknop"></v-stopknop>

        <h1>Stop de Lotto bal op 45 en <strong>maak kans op &euro;&nbsp;4.500,-!</strong></h1>

        <v-button @click.native="toGame">Speel mee</v-button>
        <v-button class="clear" @click.native="toExplain">of lees de uitleg</v-button>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { TimelineMax } from '@/vendor';
    import StopKnop from '@/app/components/StopKnop';
    import LottoBall from '@/app/components/LottoBall';
    import LottoButton from '@/app/components/LottoButton';

    export default {
        name: 'Landing',

        components: {
            'v-button': LottoButton,
            'v-ball': LottoBall,
            'v-stopknop': StopKnop
        },

        mounted()
        {
            this.$refs.ball4.startSpin();
            this.$refs.ball5.startSpin();
            this.$refs.ball6.startSpin();
        },

        methods: {
            toExplain()
            {
                this.$router.push( '/uitleg' );
            },

            toGame()
            {
                this.$router.push( '/game' );
            },

            getAnimateIn()
            {
                let tl = new TimelineMax({ delay: 1 });
                let spinXTween = this.$refs.ball3.spinXTimes( 31 );

                tl.fromTo( spinXTween, 0.05, { timeScale: 1 }, { timeScale: 0.8, ease: Expo.easeOut }, 2.35 );

                let smallest = Math.min( window.innerWidth / 2.8, 250 );

                tl.staggerFrom( '.ball-wrapper > div', 1, { y: -window.innerHeight / 1.5, ease: Bounce.easeOut }, 0.1, 0 );
                tl.staggerFrom( '.ball-wrapper .ball-shadow', 1, { y: window.innerHeight / 1.5, opacity: 0, ease: Bounce.easeOut }, 0.1, 0 );

                tl.staggerFrom( 'h1, button, .stopknop', 0.3, { opacity: 0 }, 0.1, 0.3 );

                tl.to( this.$refs.ball3.$el.querySelectorAll( '.empty-ball, .number-cap, .glow' ), 0.8, { y: -smallest, x: 20, rotation: 20, scale: 1.1, ease: Expo.easeOut }, 2.5 );
                tl.to( this.$refs.ball3.$el.querySelector( '.ball-shadow' ), 0.3, { opacity: 0.2, scale: 0.7 }, 2.5 );

                tl.add( () =>
                {
                    if( this.$refs.stopknop )
                    {
                        this.$refs.stopknop.anim();
                    }
                }, 2.5 );

                tl.from( '.motion', 0.8, { y: 220, scaleY: 0.3, opacity: 0, ease: Expo.easeOut }, 2.6 );
                tl.from( '.motion', 1.2, { x: 15, ease: Power2.easeOut }, 2.6 );

                return tl;
            },

            getAnimateOut()
            {
                let tl = new TimelineMax();

                let list = Array.prototype.slice.call( document.querySelectorAll( '.landing-view > *' ) );

                tl.staggerTo( list.reverse(), 0.3, { y: 250, opacity: 0 }, 0.05, 0 );

                return tl;
            }
        }
    };
</script>
