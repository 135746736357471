/* eslint-disable */
let fbWasInit = false;
let initCalled = false;

window.fbAsyncInit = () =>
{
    window.FB.init({
        appId: window._rootData.FB_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v2.12'
    });

    fbWasInit = true;
};

let initFb = ( d, s, id ) =>
{
    var js = d.getElementsByTagName( s )[0];
    var fjs = d.getElementsByTagName( s )[0];

    if( d.getElementById( id ) )
    {
        return;
    }
    js = d.createElement( s ); js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore( js, fjs );
};

let initTwitter = ( d, s, id ) =>
{
    var js, fjs = d.getElementsByTagName(s)[0],
    t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function(f) {
    t._e.push(f);
    };

    return t;
}

let facebookIntent = ( pUrl, pOptions = {} ) =>
{
    window.FB.ui(
        {
            method: 'share',
            href: pUrl
        },
        ( pOptions.callback ? pOptions.callback : response =>
        {
            // console.log( 'response in mixin' );
        })
    );
}

let twitterIntent = ( pUrl, pOptions ) =>
{
    let href = sharerUrls[ 'twitter' ] + encodeURIComponent( pOptions.text ) + ( pOptions.hashtags ? '&hashtags=' + pOptions.hashtags : '' );

    window.open( href, 'Twitter', 'height=285,width=550,resizable=1' );
    /*
    twttr.events.bind( 'tweet', event =>
    {
        console.log( 'has tweeted' );
    });

    console.log( twttr, this.$el );
    twttr.widgets.createShareButton(
        pUrl,
        pOptions.el,
        pOptions
    ); */
}

let sharerUrls = {
    facebook: 'https://www.facebook.com/sharer.php?u=',
    twitter: 'http://twitter.com/intent/tweet?text=',
    whatsapp: 'whatsapp://send?text='
}

let intents = {
    facebook: facebookIntent,
    twitter: twitterIntent
}

let socialShare = {
    share( pPlatform, pUrl, pOptions = {} )
    {
        intents[ pPlatform ]( pUrl, pOptions );
        return 'sharing';
    },

    url( pPlatform, pUrl, pOptions = {} )
    {
        let url = sharerUrls[ pPlatform ];

        if( pPlatform === 'facebook' )
        {
            return url += encodeURIComponent( pUrl || window.location.origin );
        }

        url += encodeURIComponent( pOptions.text );

        if( pPlatform === 'twitter' && pOptions.hashtags )
        {
            url += '&hashtags=' + encodeURIComponent( pOptions.hashtags.join( ', ' ) );
        }

        return url;
    }
};

export default {
    created()
    {
        if( !fbWasInit && !initCalled )
        {
            initCalled = true;
            initFb( document, 'script', 'facebook-jssdk' );
            initTwitter( document, 'script', 'twitter-wjs' );
        }
    },

    data()
    {
        return {
            'socialShare': socialShare
        };
    }
};


// graveyard

/*
let fbWasInit = false;
let initCalled = false;

window.fbAsyncInit = () =>
{
    window.FB.init({
        appId: window._rootData.FB_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v2.12'
    });

    fbWasInit = true;
};

let initFb = ( d, s, id ) =>
{
    var js = d.getElementsByTagName( s )[0];
    var fjs = d.getElementsByTagName( s )[0];
    console.log( d.getElementById( id ) );
    if( d.getElementById( id ) )
    {
        return;
    }
    js = d.createElement( s ); js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore( js, fjs );
};

let shareFacebook = ( pUrl, pOptions ) =>
{
    if( initCalled && !fbWasInit )
    {
        return;
    }

    window.FB.ui(
        {
            method: 'share',
            href: pUrl
        },
        response =>
        {
            if( pOptions && typeof pOptions.callback === 'function' )
            {
                pOptions.callback( response );
            }
        }
    );
};

let shareTwitter = ( pUrl, pOptions ) =>
{
    return 'http://twitter.com/intent/tweet?text=' + encodeURIComponent( pOptions.text + ' ' + pUrl ) + '&hashtags=' + pOptions.hashtags.join( ', ' );
};

let shareWhatsapp = ( pUrl, pOptions ) =>
{
    return 'whatsapp://send?text=' + encodeURIComponent( pOptions.text + ' ' + pUrl );
};

export default {
    created()
    {
        if( !fbWasInit && !initCalled )
        {
            initCalled = true;
            initFb( document, 'script', 'facebook-jssdk' );
        }
    },

    methods: {
        socialShare( pPlatform, pUrl, pOptions )
        {
            switch( pPlatform )
            {
                case 'facebook':
                    shareFacebook( pUrl, pOptions );
                    break;
                case 'twitter':
                    return shareTwitter( pUrl, pOptions );
                case 'whatsapp':
                    return shareWhatsapp( pUrl, pOptions );
                default:
                    break;
            }
        }
    }
};
*/
