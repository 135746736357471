<template>
    <div class="stopknop button-wrapper" @mousedown="emitPress" @tap="emitPress">
        <img class="behind" src="/static/img/knop/behind2.png" />
        <img class="pressable" src="/static/img/knop/press.png" />
        <img class="base" src="/static/img/knop/base2.png" />
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { TimelineMax, TweenMax } from '@/vendor';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: true
            }
        },

        data()
        {
            return {
                pressed: false
            };
        },

        watch: {
            pressed: function( pVal )
            {
                if( pVal === true )
                {
                    this.anim();
                }
            }
        },

        mounted()
        {
            TweenMax.set( '.pressable', { x: '-50%' });
        },

        methods: {
            emitPress()
            {
                if( this.pressed === true || this.disabled === true )
                {
                    return;
                }

                this.pressed = true;
                this.$emit( 'pressed' );
            },

            anim()
            {
                let tl = new TimelineMax();
                tl.to( '.pressable', 0.2, { y: '25%', ease: Power2.easeOut }, 0 );

                tl.to( '.pressable', 2.5, { y: '0%' }, 0.3 );
                tl.add( () => { this.pressed = false; }, 2.5 );
            }
        }
    };
</script>
