<template>
    <div class="game-view">
        <div class="beach">
            <div class="sky"></div>
            <div class="sea"></div>
        </div>
        <div class="cloud-wrapper">
            <img class="top-cloud" src="/static/img/cloud.png" />
            <img class="top-cloud" src="/static/img/cloud.png" />
            <img class="middle-cloud" src="/static/img/cloud.png" />
            <img class="bottom-cloud" src="/static/img/cloud.png" />
            <img class="bottom-cloud" src="/static/img/cloud.png" />
        </div>

        <v-encouragement ref="encouragement" :attempt="previousAttempt" :ballNum="ballNum"></v-encouragement>

        <v-confetti class="confetti-wrapper" ref="confetti"></v-confetti>

        <div class="ball-wrapper">
            <v-ball :number="1" :spinTime="0.11" ref="ball1" @stoppedAt="saveNumber" ></v-ball>
            <v-ball :number="2" :spinTime="0.12" ref="ball2" @stoppedAt="saveNumber" ></v-ball>
            <v-ball :number="3" :spinTime="0.105" ref="ball3" @stoppedAt="saveNumber" ></v-ball>
            <v-ball :number="4" :spinTime="0.11" ref="ball4" @stoppedAt="saveNumber" ></v-ball>
            <v-ball :number="5" :spinTime="0.1" ref="ball5" @stoppedAt="saveNumber" ></v-ball>
            <v-ball :number="6" :spinTime="0.115" ref="ball6" @stoppedAt="saveNumber" ></v-ball>
        </div>

        <h2 class="attempt-line" :style="{ opacity: ( currentAttempt > 0 && currentAttempt < 6 ? '1': '0' ) }">Nog {{ 6 - currentAttempt }} poging{{ currentAttempt === 5 ? '' : 'en' }}!</h2>

        <v-stopknop @pressed="stopBall" :disabled="disableUserInput"></v-stopknop>

        <transition name="fade">
            <div class="continue-wrapper" v-if="currentAttempt > 0 && currentAttempt < 6">
                <!-- <h2>Nog {{ 6 - currentAttempt }} poging{{ currentAttempt === 5 ? '' : 'en' }}!</h2> -->
                <v-button class="continue black" @click.native="toNaw">Tevreden? Ga verder</v-button>
            </div>
        </transition>

        <div class="something"></div>
    </div>
</template>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.game-view{
    overflow:hidden;
}
</style>

<script>
    import { dottify } from '@/app/mixins';
    import { TimelineMax, TweenMax } from '@/vendor';
    import LottoBall from '@/app/components/LottoBall';
    import LottoButton from '@/app/components/LottoButton';
    import Encouragement from '@/app/components/Encouragement';
    import Confetti from '@/app/components/Confetti';
    import StopKnop from '@/app/components/StopKnop';

    export default {
        components: {
            'v-button': LottoButton,
            'v-ball': LottoBall,
            'v-confetti': Confetti,
            'v-encouragement': Encouragement,
            'v-stopknop': StopKnop
        },

        mixins: [ dottify ],

        data()
        {
            return {
                ballNum: null,
                currentAttempt: 0,
                encourType: null,
                disableUserInput: true
            };
        },

        computed: {
            prize()
            {
                return this.dottify( this.$store.getters[ 'game/prize' ] );
            },

            previousAttempt()
            {
                return this.currentAttempt - 1;
            }
        },

        mounted()
        {
            this.cloudTl = new TimelineMax(); // wordt later gebruikt, maar moet hier gedefineerd want de resize functie gebruikt hem ook

            this.balls = [ this.$refs.ball1, this.$refs.ball2, this.$refs.ball3, this.$refs.ball4, this.$refs.ball5, this.$refs.ball6 ];

            for( let i = 0; i < this.balls.length; i++ )
            {
                this.balls[i].presentTween = TweenMax.from( this.balls[i].$el, 1, { scale: 0.8, y: -10, ease: Sine.easeInOut, paused: true });
            }

            this.balls[0].presentTween.seek( 1 );

            window.addEventListener( 'resize', this.onResize.bind( this ) );
            this.onResize();
        },

        methods: {
            onResize()
            {
                if( window.innerWidth > 450 && window.innerWidth < 840 )
                {
                    TweenMax.to( '.ball-wrapper', 0.3, { x: -220 * this.currentAttempt, ease: Sine.easeInOut });
                }
                else if( window.innerWidth <= 450 && window.innerHeight < 850 )
                {
                    TweenMax.to( '.ball-wrapper', 0.3, { x: -140 * this.currentAttempt, ease: Sine.easeInOut });
                }

                this.cloudTl.timeScale( 1 - Math.min( 0.75, window.innerWidth / 2500 ) );
            },

            stopBall()
            {
                if( this.disableUserInput === true || this.currentAttempt >= 6 )
                {
                    return;
                }

                if( this.gameTl && this.gameTl.isActive() )
                {
                    this.gameTl.kill();
                }

                this.gameTl = new TimelineMax();

                // doe wat dingen gelijk
                this.gameTl.add( () =>
                {
                    this.balls[ this.currentAttempt ].stopSpin();
                    this.balls[ this.currentAttempt ].jump();

                    this.$refs.encouragement.hide();
                    this.disableUserInput = true;

                    if( this.previousAttempt >= 0 )
                    {
                        TweenMax.to( this.balls[ this.currentAttempt - 1 ].$el, 1, { opacity: 0.5 });

                        this.balls[ this.currentAttempt - 1 ].presentTween.reverse();
                        this.balls[ this.currentAttempt - 1 ].spinXTimes( 1, 'x' );
                    }

                    if( this.currentAttempt < 5 )
                    {
                        this.balls[ this.currentAttempt + 1 ].startSpin();
                        this.balls[ this.currentAttempt + 1 ].presentTween.play();
                    }
                }, 0 );

                // hide, en verplaats dan confetti
                this.gameTl.to( '.confetti-wrapper', 0.1, { opacity: 0 }, 0 );
                this.gameTl.to( '.confetti-wrapper', 0.3, {
                    x: this.balls[ this.currentAttempt ].$el.getBoundingClientRect().left - 100,
                    y: this.balls[ this.currentAttempt ].$el.getBoundingClientRect().top - 100
                }, 0.11 );

                // herspawn confetti
                this.gameTl.add( () =>
                {
                    TweenMax.set( '.confetti-wrapper', { opacity: 1 });
                    this.$refs.confetti.spawnConfetti( 100 );
                }, 0.5 );

                this.gameTl.add( () =>
                {
                    if( this.currentAttempt < 5 )
                    {
                        if( window.innerWidth < 450 && window.innerHeight < 850 )
                        {
                            TweenMax.to( '.ball-wrapper', 1, { x: '-=' + 140, ease: Expo.easeInOut });
                        }
                        else if( window.innerWidth < 840 )
                        {
                            TweenMax.to( '.ball-wrapper', 1, { x: '-=' + 220, ease: Expo.easeInOut });
                        }
                    }

                    this.currentAttempt++;
                }, 1.3 );

                // enable user input
                this.gameTl.add( () =>
                {
                    if( this.currentAttempt < 6 )
                    {
                        this.disableUserInput = false;
                    }
                }, 2.5 );

                this.gameTl.add( () =>
                {
                    if( this.currentAttempt > 5 )
                    {
                        this.$router.push( '/gegevens/1' );
                    }
                }, 5 );
            },

            saveNumber( pAmount )
            {
                this.ballNum = pAmount;
                if( this.currentAttempt === 0 || this.currentAttempt === 5 )
                {
                    switch( true )
                    {
                            case ( pAmount < 11 ):
                                this.encourType = 0;
                                break;
                            case ( pAmount < 26 ):
                                this.encourType = 1;
                                break;
                            case ( pAmount < 45 ):
                                this.encourType = 2;
                                break;
                            case ( pAmount === 45 ):
                                this.encourType = 3;
                                break;
                    }
                }
                else
                {
                    if( pAmount === 45 )
                    {
                        this.encourType = 2;
                    }
                    else if( pAmount < this.lastNum )
                    {
                        this.encourType = 0;
                    }
                    else
                    {
                        this.encourType = 1;
                    }
                }

                this.$store.dispatch( 'game/saveNumber', pAmount );

                this.lastNum = pAmount;
            },

            toNaw()
            {
                TweenMax.killAll();
                this.$router.push( '/gegevens/1' );
            },

            getAnimateIn()
            {
                this.cloudTl.staggerFromTo( '.top-cloud', 20, { left: '100%' }, { left: '-60%', ease: Linear.easeNone, repeat: -1 }, 10, 1 );
                this.cloudTl.staggerFromTo( '.middle-cloud', 16, { left: '100%' }, { left: '-60%', ease: Linear.easeNone, repeat: -1 }, 8, 3 );
                this.cloudTl.staggerFromTo( '.bottom-cloud', 12, { left: '100%' }, { left: '-40%', ease: Linear.easeNone, repeat: -1 }, 6, 1.5 );
                this.cloudTl.seek( 14 );

                let tl = new TimelineMax({ delay: 0.3 });

                tl.from( '.beach, .cloud-wrapper', 0.75, { y: -500 }, 0 );

                tl.staggerFrom( '.lotto-ball', 0.8, { y: -window.innerHeight / 1.5, ease: Bounce.easeOut }, 0.1, 0.75 );
                tl.staggerFrom( '.lotto-ball:first-of-type > .ball-shadow', 0.8, { y: ( window.innerHeight / 1.5 ), opacity: 0, ease: Bounce.easeOut }, 0.1, 0.75 );
                tl.staggerFrom( '.lotto-ball:not(:first-of-type) > .ball-shadow', 0.8, { y: window.innerHeight / 1.5 / 0.81, opacity: 0, ease: Bounce.easeOut }, 0.1, 0.85 );

                tl.from( '.stopknop', 0.3, { opacity: 0 }, 1.3 );

                for( let i = 0; i < this.balls.length; i++ )
                {
                    // tl.add( () => { this.balls[i].startSpin(); }, 1.3 );
                }

                tl.add( () => { this.balls[0].startSpin(); }, 1.3 );

                tl.add( () => { this.disableUserInput = false; }, 2.5 );

                return tl;
            }
        }
    };
</script>
