<template>
    <div>
        <div class="debug-panel" v-if="debug">
            <button class="spawn" @click="spawnConfetti( confettiAmount )">Spawn confetti</button>
            <p>confettiAmount: {{ confettiAmount }}</p>
            <button @click="addConfetti(-50)" class="amount-edit">-50</button><button @click="addConfetti(50)" class="amount-edit">+50</button>
        </div>

        <div class="confetti-container" ref="canvasContainer"></div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    /* eslint-disable */
    import Confetti from '@/vendor/mrynk/Confetti';
    import PIXIManager from '@/vendor/mrynk/PIXIManager';

    export default {
        props: {
            debug: {
                type: Boolean,
                default: false
            }
        },

        data()
        {
            return {
                canvasWidth: 400,
                canvasHeight: 400,

                pixiManager: null,
                confetti: null,
                confettiAmount: 50
            };
        },

        computed:
        {
            confettiIncrease()
            {
                if( canvasWidth > 420 )
                {
                    return 50;
                }

                return 25;
            },

            confettiAreaSize()
            {
                return Math.min( 700, this.canvasWidth );
            }
        },

        mounted()
        {
            this.setupPixiCanvas();

            window.addEventListener( 'resize', this.resize );
        },

        methods: {
            resize()
            {
                this.canvasWidth = 400;
                this.canvasHeight = 400;

                this.pixiManager.resize( this.canvasWidth, this.canvasHeight );
            },

            addConfetti( pNum )
            {
                this.confettiAmount += pNum;
            },

            setupPixiCanvas()
            {
                this.canvasWidth = 400;
                this.canvasHeight = 400;
                this.pixiManager = new PIXIManager({ width: this.canvasWidth, height: this.canvasHeight });
                this.confetti = new Confetti();

                this.pixiManager.stage.addChild( this.confetti.sprite );
                this.$refs.canvasContainer.appendChild( this.pixiManager.canvas );
            },

            spawnConfetti( pAmount )
            {
                this.confetti.spawn( pAmount, this.confettiAreaSize, this.confettiAreaSize, { x: this.canvasWidth / 2, y: this.canvasHeight * 0.45 });
            }
        }
    };
</script>
