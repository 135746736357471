export default {
    namespaced: true,

    state: {
        prize: 0
    },

    getters: {
        prize: state => state.prize
    },

    mutations: {
        setPrize( state, payload )
        {
            state.prize = payload;
        }
    },

    actions: {
        restartGame( context )
        {
            context.commit( 'setPrize', 0 );
        },

        saveNumber( context, payload )
        {
            if( typeof payload === 'number' )
            {
                context.commit( 'setPrize', payload * 100 );
            }
        }
    }
};
