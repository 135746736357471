import Vue from 'vue';
import Router from 'vue-router';

import Landing from '@/app/pages/Landing';
// import Naw from '@/app/pages/Naw';
import Game from '@/app/pages/Game';
import Explain from '@/app/pages/Explain';
import Thanks from '@/app/pages/Thanks';

import store from '@/app/store';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/bedankt',
            name: 'Thanks',
            component: Thanks
        },
        {
            path: '/gegevens',
            component: () =>
            {
                return import( /* webpackChunkName: 'form' */ '@/app/pages/Naw' );
            },
            beforeEnter: ( to, from, next ) =>
            {
                if( to.name === 'FullForm' )
                {
                    next( '/form/1' );
                }
                else
                {
                    next();
                }
            },
            children: [
                {
                    path: '1',
                    name: 'naw1',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form1' */ '@/app/pages/naw/Form1' );
                    }
                },

                {
                    path: '2',
                    name: 'naw2',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form1' */ '@/app/pages/naw/Form2' );
                    }
                },

                {
                    path: '3',
                    name: 'naw3',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form1' */ '@/app/pages/naw/Form3' );
                    }
                }
            ]
        },
        {
            path: '/game',
            name: 'Game',
            component: Game
        },
        {
            path: '/uitleg',
            name: 'Explain',
            component: Explain
        },
        {
            path: '/share/:id',
            name: 'SharePath',
            beforeEnter: ( to, from, next ) =>
            {
                next( '/' );
            }
        },
        {
            path: '/',
            name: 'Landing',
            component: Landing
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    let trackingAlias = {
        '/': '/landing',
        '/game': '/game',
        '/uitleg': '/uitleg',
        '/gegevens': '/gegevens',
        '/bedankt': '/bedankt'
    };

    dataLayer.push({
        event: 'pageview',
        page_path: trackingAlias[ to.fullPath ]
    });

    if( to.path === '/bedankt' )
    {
        let entry = store.getters['entry/get']();

        dataLayer.push({
            event: 'confirmation',
            newsletter_optin: entry.kit
            // nyx_id: '1234'
        });
    }

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
